.container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  @include adaptiveFull('padding-left', 240, 110, 25);
  @include adaptiveFull('padding-right', 240, 110, 25);

  @include vp-1279 {
    @include adaptiveFull('padding-left', 240, 85, 25);
    @include adaptiveFull('padding-right', 240, 85, 25);
  }

  @include vp-1023 {
    @include adaptiveFull('padding-left', 240, 75, 25);
    @include adaptiveFull('padding-right', 240, 75, 25);
  }
}
