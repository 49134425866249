.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  color: #1B1A16;
  text-align: center;
  @include adaptiveFull('font-size', 16, 14, 14);
  font-weight: 400;
  line-height: 1.38;
  text-transform: uppercase;
  border-radius: 100px;
  background: #FFD289;
  padding: 9px 24px;
  @include adaptiveFull('padding-top', 9, 5, 5);
  @include adaptiveFull('padding-bottom', 9, 5, 5);
  transition: $trans-default;
  min-width: 180px;

  @include hover-focus {
    background-color: #1B1A16;
    color: #FFD289;
    border-color: transparent;
  }

  &--transparent {
    --border-color: #1B1A16;
    background: unset;
    border-radius: 100px;
    border: 1px solid var(--border-color);
  }

  &--gold {
    --border-color: #FFD289;
    color: #FFD289;
  }
}
