.recipes {
}
.recipes__title {
}
.recipes__wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  @include adaptiveFull('gap', 80, 60, 40);

  @include vp-1279 {
    @include adaptiveFull('gap', 80, 5, 40);
  }

  @include vp-1023 {
    grid-template-columns: repeat(2, 1fr);
  }

  @include vp-767 {
    grid-template-columns: auto;
  }
}
.recipes__main {
  border-top: 1px solid #FFD289;
  @include adaptiveFull('padding-top', 40, 28, 20);
}
.recipes__subtitle {
  margin-bottom: 30px;
}
.recipes__description {
  @include adaptiveFull('margin-bottom', 40, 28, 20);
}
.recipes__link {
}
.recipes__slider-wrapper {
  @include adaptiveFull('max-width', 940, 692, 340);
  position: relative;

  .swiper-navigation {
  }
  .swiper-navigation__btn {
    position: absolute;
    top: 50%;
    @include adaptiveFull('left', -65, -40, 5);
    transform: translateY(-50%);
    z-index: 1;

    &.swiper-button-disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    svg {
      @include adaptiveFull('width', 21, 15, 15);
      @include adaptiveFull('height', 42, 30, 30);
    }

    @include vp-767 {
      filter: invert(1);
    }
  }
  .swiper-navigation__btn--prev {
    svg {
      transform: rotate(180deg);
    }
  }
  .swiper-navigation__btn--next {
    left: unset!important;
    @include adaptiveFull('right', -65, -40, 5);
  }

  @include vp-1279 {
    @include adaptiveFull('max-width', 940, 680, 340);
  }

  @include vp-1023 {
    @include adaptiveFull('max-width', 940, 330, 326);
  }

  @include vp-767 {
    width: 92dvw;
  }
}
.recipes__slider {
}
.recipes__slide {
  position: relative;
}
.recipes__slide-photo {
  width: 100%;
  height: 100%;
  min-height: 480px;
  object-fit: cover;
}
.recipes__slide-info {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  color: #FFFCF4;
  font-weight: 500;
  width: 90%;
  border-radius: 30px 30px 0px 0px;
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(15px);
  @include adaptiveFull('padding', 25, 18, 16);

  @include vp-1279 {
    width: 95%;
    @include adaptiveFull('padding', 25, 16, 16);
  }
}
.recipes__slide-title {
  margin: 0;
  @include adaptiveFull('margin-bottom', 40, 30, 30);
  font-weight: 500;
  max-width: 75%;
  line-height: 1.22;
}
.recipes__slide-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    @include adaptiveFull('width', 44, 30, 30);
  }
}
.recipes__slide-time {
  display: flex;
  align-items: center;
  @include adaptiveFull('gap', 15, 10, 10);
  font-weight: 300;
}
.recipes__slide-time-icon {
  flex-shrink: 0;
  @include adaptiveFull('width', 44, 30, 30);
}