$maxWidth: 1920;
$startWidth: 1920;
$midWidth: 1280;
$endWidth: 375;

@mixin adaptiveFull($property, $startSize, $midSize, $endSize) {
  $addSizeMid: $startSize - $midSize;
  $addSizeEnd: $midSize - $endSize;
  #{$property}: $startSize + px;
  @media (max-width: #{$startWidth + px}) {
    #{$property}: calc(#{$midSize + px} + #{$addSizeMid} * ((100vw - #{$midWidth + px}) / #{$startWidth - $midWidth}));
  }
  @media (max-width: #{$midWidth + px}) {
    #{$property}: calc(#{$endSize + px} + #{$addSizeEnd} * ((100vw - #{$endWidth + px}) / #{$midWidth - $endWidth}));
  }
}

@mixin adaptiveDesktopToMobile($property, $startSize, $endSize) {
  $addSize: $startSize - $endSize;
  #{$property}: $startSize + px;
  @media (max-width: #{$startWidth + px}) {
    #{$property}: calc(#{$endSize + px} + #{$addSize} * ((100vw - #{$endWidth + px}) / #{$startWidth - $endWidth}));
  }
}

@mixin adaptiveLargeDesktopToMobile($property, $startSize, $endSize) {
  $addSize: $startSize - $endSize;
  #{$property}: calc(#{$endSize + px} + #{$addSize} * ((#{$maxWidth + px} - #{$endWidth + px}) / #{$startWidth - $endWidth}));
  @media screen and (max-width: #{$maxWidth + px}) {
    #{$property}: calc(#{$endSize + px} + #{$addSize} * ((100vw - #{$endWidth + px}) / #{$startWidth - $endWidth}));
  }
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin media-1 {
  @media screen and (max-width: 1919px) and (min-width: 1441px) {
    @content;
  }
}

@mixin media-2 {
  @media screen and (max-width: 1440px) and (min-width: 1024px) {
    @content;
  }
}

@mixin media-3 {
  @media screen and (min-width: 1921px) {
    @content;
  }
}


@mixin zoomBody {
  @include media-1 {
    body {
      zoom: 0.9;
    }
  }
  @include media-2 {
    body {
      zoom: 0.9;
    }
  }
}

@mixin correctWidth {
  @include media-1 {
    width: calc(50vw * 1.11);
  }
  @include media-2 {
    width: calc(50vw * 1.11);
  }
}

@mixin yearsSideTextCorrect {
  @include media-1 {
    @include adaptiveFull('width', 795, 439, 340);
  }
  @include media-2 {
    @include adaptiveFull('width', 840, 439, 340);
  }
  @include media-3 {
    @include adaptiveFull('--padding', 130, 36, 16);
    width: calc(100vw - 50vw - 130px - 150px - 8px)
  }
}

@mixin animation-text {
  --animation-on-visible: true;

  opacity: 0;
  transition: opacity 0.5s, transform 0.5s;

  &.visible {
    opacity: 1;
    transform: translateX(0);
  }
}

@mixin animation-text-from-left {
  @include animation-text;
  transform: translateX(-100%);

  @include vp-1439 {
    transform: translateX(-80%);
  }
}

@mixin animation-text-from-right {
  @include animation-text;
  transform: translateX(100%);

  @include vp-1439 {
    transform: translateX(80%);
  }
}




@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

@mixin hover {
  @media (hover: hover) {
    &:hover:not(.focus-visible) {
      @content;
    }
  }
}

@mixin focus {
  &.focus-visible:focus {
    @content;
  }
}

@mixin active {
  &.focus-visible:active {
    @content;
  }
}

@mixin hover-focus {
  @media (hover: hover) {
    &:hover:not(.focus-visible) {
      @content;
    }
  }

  &.focus-visible:focus {
    @content;
  }
}

// Desktop first

@mixin vp-1919 {
  @media (max-width: ($vp-1920 - 1px)) {
    @content;
  }
}

@mixin vp-1439 {
  @media (max-width: ($vp-1440 - 1px)) {
    @content;
  }
}

@mixin vp-1365 {
  @media (max-width: ($vp-1366 - 1px)) {
    @content;
  }
}

@mixin vp-1279 {
  @media (max-width: ($vp-1280 - 1px)) {
    @content;
  }
}

@mixin vp-1023 {
  @media (max-width: ($vp-1024 - 1px)) {
    @content;
  }
}

@mixin vp-767 {
  @media (max-width: ($vp-768 - 1px)) {
    @content;
  }
}

@mixin vp-374 {
  @media (max-width: ($vp-375 - 1px)) {
    @content;
  }
}

// Mobile first

// @mixin vp-375 {
//   @media (min-width: $vp-375) {
//     @content;
//   }
// }

// @mixin vp-768 {
//   @media (min-width: $vp-768) {
//     @content;
//   }
// }

// @mixin vp-1024 {
//   @media (min-width: $vp-1024) {
//     @content;
//   }
// }

// @mixin vp-1280 {
//   @media (min-width: $vp-1280) {
//     @content;
//   }
// }

// @mixin vp-1440 {
//   @media (min-width: $vp-1440) {
//     @content;
//   }
// }

// @mixin vp-1920 {
//   @media (min-width: $vp-1920) {
//     @content;
//   }
// }

// ---------------------------------

// не нужно добавлять свои миксины для ресета списков, обнуления кнопок и прочие
// этим вы усложняете работу команды, проверку вашего кода и будущую поддержку проекта
