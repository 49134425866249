.about {
}
.about__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @include adaptiveFull('gap', 60, 40, 25);

  @include vp-1023 {
    grid-template-columns: repeat(2, 1fr);
  }

  @include vp-767 {
    grid-template-columns: repeat(1, 1fr);
  }
}
.about__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include vp-1023 {
    &:last-child {
      grid-column: span 2;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      @include adaptiveFull('gap', 60, 40, 10);
    }
  }

  @include vp-767 {
    &:last-child {
      grid-column: auto;
      grid-template-columns: repeat(1, 1fr);
    }
    
  }
}
.about__item-title {
  @include adaptiveFull('margin-bottom', 30, 20, 20);

  @include vp-1279 {
    br {
      display: none;
    }
  }
  @include vp-1023 {
    br {
      display: inline;
    }
  }
  @media (max-width: 374px) {
    @include adaptiveFull('font-size', 20, 16, 14);
  }
}
.about__item-description {
  margin: 0;
}
.about__item-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;

  &:last-child:not(:first-child) {
    @include adaptiveFull('margin-top', 50, 30, 20);
  }

  &:first-child:not(:last-child) {
    @include adaptiveFull('margin-bottom', 50, 30, 20);
  }
}
.about__link {
  @include adaptiveFull('margin-top', 30, 20, 20);
}