html, 
body {
  scroll-behavior: smooth;
}

.title {
  &-h2 {
    font-family: $ff-primary;
    @include adaptiveFull('font-size', 32, 24, 24);
    font-weight: 700;
    line-height: 106.25%;
    text-transform: uppercase;
    margin: 0;
  }
  &-h3 {
    @include adaptiveFull('font-size', 20, 16, 16);
    font-weight: 400;
    line-height: 1.3;
    text-transform: uppercase;
    margin: 0;
  }
}

.section {
  @include adaptiveFull('padding-top', 100, 80, 50);
  @include adaptiveFull('padding-bottom', 100, 80, 50);

  &--bg-gold {
    background: #FFD289;
  }

  &--bg-dark {
    background: #1B1A16;
  }
}
.section__title {
  @include adaptiveFull('margin-bottom', 60, 40, 30);
}

.mobile {
  display: none!important;
}

@include vp-767 {
  .desktop {
    display: none!important;
  }
  .mobile {
    display: block!important;
  }
}