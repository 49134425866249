.footer {
  background: #FFFCF4;
  overflow: hidden
}
.footer__bottom {
  @include adaptiveFull('padding-top', 35, 35, 40);
  @include adaptiveFull('padding-bottom', 35, 35, 40);
  display: flex;
  justify-content: space-between;
  color: $color-tundora;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100dvw;
    height: 10px;
    background: #FFD289;
    z-index: 11;
  }

  @include vp-767 {
    flex-direction: column;
    text-align: center;
  }
}
.footer__left {
  @include vp-767 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }
}
.footer__logo {
  display: block;
  @include adaptiveFull('margin-bottom', 20, 20, 24);
  width: fit-content;
}
.footer__logo-img {
}
.footer__social {
  display: flex;
  @include adaptiveFull('margin-bottom', 20, 20, 24);

  @include vp-767 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.footer__social-link {
  width: 40px;
  height: 40px;
  background-color: $color-bokara-grey;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $trans-default;

  @include hover-focus {
    background-color: $color-equator;
  }

  &:not(:last-child) {
    margin-right: 8px;
  }
}
.footer__social-icon {
}
.footer__copyright {
  color: #57504C;
  font-size: 12px;

  a {
    text-decoration: underline;
  }
}
.footer__right {
  text-align: right;

  @include vp-767 {
    text-align: center;
  }
}
.footer__phone {
  font-size: 24px;
  font-weight: 700;
  display: block;
  margin-bottom: 10px;
}
.footer__email {
  font-size: 16px;
  font-weight: 500;
  text-decoration-line: underline;
  margin-bottom: 20px;
  display: block;
}
.footer__address {
  color: #57504C;
  font-size: 16px;
  margin-bottom: 20px;
}
.footer__document {
  font-size: 12px;
  text-decoration-line: underline;
}
.footer__more {
  @include vp-767 {
    margin: 20px 20px 0;
  }
}