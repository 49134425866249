.tastes {
}
.tastes__title {
}
.tastes__slider-wrapper {
  position: relative;

  .swiper-navigation {
  }
  .swiper-navigation__btn {
    position: absolute;
    top: 50%;
    @include adaptiveFull('left', -65, -40, 0);
    transform: translateY(-50%);
    z-index: 1;

    &.swiper-button-disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    svg {
      @include adaptiveFull('width', 21, 15, 15);
      @include adaptiveFull('height', 42, 30, 30);
    }

    @include vp-767 {
      top: 55%;
    }
  }
  .swiper-navigation__btn--prev {
    svg {
      transform: rotate(180deg);
    }

    @include vp-767 {
      opacity: 0;
    }
  }
  .swiper-navigation__btn--next {
    left: unset!important;
    @include adaptiveFull('right', -65, -40, 0);
  }
}
.tastes__slider {
  .swiper {
  }
  .swiper-wrapper {
  }
  .swiper-slide {
  }
}
.tastes__slide {
  padding-top: 30px;
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: #FFD289;
    @include adaptiveFull('width', 440, 290, 160);
    max-width: 100%;
    height: 2px;
  }

  @include vp-1279 {
    &::before {
      width: 100%;
    }
  }
}
.tastes__slide-header {
  grid-column: span 2;
  max-width: 78%;

  @include vp-1279 {
    grid-column: auto
  }

  @include vp-1023 {
    max-width: unset;
  }

  @include vp-767 {
    grid-column: span 2;
  }
}
.tastes__slide-title {
}
.tastes__slide-subtitle {
  @include adaptiveFull('font-size', 18, 14, 14);
  line-height: 1.22;
  text-transform: uppercase;
  max-width: 480px;
  margin: 0;
  @include adaptiveFull('margin-top', 30, 22, 20);
  // @include adaptiveFull('margin-bottom', 30, 22, 20);
}
.tastes__slide-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @include adaptiveFull('gap', 30, 22, 20);
  flex-grow: 1;

  @include vp-1365 {
    grid-template-columns: 1.2fr 1fr;
  }

  @include vp-1279 {
    grid-template-columns: 1fr 1.5fr;
  }

  @include vp-1023 {
    grid-template-columns: 1fr 1.1fr;
  }

  @include vp-767 {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
  }
}
.tastes__slide-photo {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  @include adaptiveFull('height', 480, 350, 210);
  grid-row: span 2;

  @include vp-1279 {
    order: -1;
    grid-row: span 3;
    @include adaptiveFull('height', 480, 520, 210);
  }

  @include vp-767 {
    order: 1;
    grid-row: auto;
  }
}
.tastes__slide-main {
  display: flex;
  flex-direction: column;

  @include vp-1023 {
    max-width: unset;
  }
}
.tastes__slide-description {
  margin: 0;
  @include adaptiveFull('font-size', 16, 12, 12);
  font-weight: 300;
  line-height: 1.25;

  @include vp-1279 {
    max-width: 78%;
  }

  @include vp-767 {
    max-width: unset;
    grid-column: span 2;
  }
}
.tastes__slide-info {
  margin-top: auto;
  margin-bottom: 20px;
  @include adaptiveFull('font-size', 16, 12, 12);
  font-weight: 300;
  line-height: 1.25;

  @media (max-width: 374px) {
    @include adaptiveFull('font-size', 16, 12, 10);
  }
}
.tastes__slide-info-item {
  display: flex;
  margin: 0;
}
.tastes__slide-info-title {
  margin: 0 5px 0 0;
}
.tastes__slide-info-value {
  margin: 0;
}
.tastes__slide-preview {
  @include adaptiveFull('width', 240, 180, 140);

  @include vp-767 {
    width: 100%;
  }
}
.tastes__link {
  margin: 0 auto;
  @include adaptiveFull('margin-top', 55, 36, 25);
}
.tastes__slide-footer {
  align-self: flex-end;

  @include vp-767 {
    order: 2;
  }
}