.header {
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;
  color: $color-white;
  font-size: 16px;
  padding: 16px 0;
  @include adaptiveDesktopToMobile('padding-top', 75, 34);
  font-family: $ff-primary;

  .container {
    display: flex;

    @include vp-1023 {
      justify-content: space-between;
    }
  }
}
.header__logo {
  @include adaptiveFull('width', 220, 162, 120);
}
.header__logo-img {
}
.header__menu {
  margin-left: auto;
  @include adaptiveFull('margin-right', 60, 0, 0);

  @include vp-1023 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    transition: $trans-default;
    display: flex;
    justify-content: flex-end;

    &.is-active {
      opacity: 1;
      pointer-events: all;
      background: rgba(0, 0, 0, 0.41);
      backdrop-filter: blur(7.349999904632568px);
    }
  }
}
.header__menu-list {
  @include reset-list;

  display: flex;
  @include adaptiveFull('margin-left', -20, -15, -10);
  @include adaptiveFull('margin-right', -20, -15, -10);

  @include vp-1023 {
    background: #1B1A16;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    max-width: 520px;
    margin-left: auto;
    padding: 45px;
    height: fit-content
  }
}
.header__menu-item {
  @include adaptiveFull('margin-left', 20, 15, 10);
  @include adaptiveFull('margin-right', 20, 15, 10);

  &--mobile {
    display: none;
  }

  @include vp-1023 {
    margin: 0;

    &:first-child {
      align-self: flex-start;
    }

    &--mobile {
      display: block;
    }
  }
}
.header__menu-logo {
  margin-bottom: 50px;
}
.header__menu-close {
  display: none;

  @include vp-1023 {
    display: block;
    position: absolute;
    @include adaptiveFull('width', 36, 36, 35);
    @include adaptiveFull('height', 36, 36, 35);
    @include adaptiveFull('top', 35, 35, 35);
    @include adaptiveFull('right', 36, 36, 35);
  }
}
.header__menu-link {
  display: block;
  transition: $trans-default;
  @include adaptiveFull('font-size', 20, 16, 16);
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;

  @include hover-focus {
    color: $color-equator;
  }
  
  &--primary {
    color: $color-equator;
  }

  @include vp-1023 {
    padding-bottom: 20px;
  }
}
.header__menu-item-list {
  padding-top: 10px;
  @include reset-list;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: $trans-default;
  transform: translateY(100%);

  @include vp-1023 {
    position: static;
    transform: unset;
    display: none;
    padding: 0;
  }
}
.header__menu-item-list-item {
}
.header__menu-item-list-link {
  display: block;
  padding: 12px 20px;
  background-color: $color-bokara-grey;
  border-top: 1px solid #363433;
  white-space: nowrap;
  transition: $trans-default;

  @include hover-focus {
    color: $color-equator;
  } 

  @include vp-1023 {
    border: unset;

    &:first-child {
      padding-top: 0;
    }
  }
}
.header__contacts {
  padding: 11px 54px;
  border-radius: 5px;
  border: 1px solid $color-white;
  transition: $trans-default;

  @include hover-focus {
    border: 1px solid $color-equator;
    background-color: $color-equator;
    color: #2C2927;
  }

  @include vp-1023 {
    display: none;
  }
}
.header__phone {
  margin-left: auto;
  margin-right: 50px;

  display: none;

  @include vp-1023 {
    display: block;
  }
}
.header__phone-icon {
}
.header__hamburger {
  display: none;

  @include vp-1023 {
    display: block;
    margin-top: -15px;
  }
}
.header__hamburger-icon {
}
.header__social {
  display: flex;
  gap: 8px;
  margin-top: 65px;
}