.secret {
  overflow: hidden;
}
.secret__wrapper {
  display: flex;
  align-items: center;

  @include vp-767 {
    flex-direction: column;
  }
}
.secret__bg {
  @include adaptiveFull('width', 720, 530, 326);

  @include vp-767 {
    width: 90%;
  }
}
.secret__main {
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(15px);
  @include adaptiveFull('margin-left', -120, -90, -90);
  @include adaptiveFull('padding', 60, 40, 20);
  color: #FFFCF4;
  @include adaptiveFull('min-width', 850, 616, 580);

  @include vp-1279 {
    @include adaptiveFull('margin-left', -120, -160, -460);
  }

  @include vp-767 {
    margin: -160px 0 0;
    min-width: unset;
    width: 100%;
  }
}
.secret__title {
  color: #FFD289;
  @include adaptiveFull('max-width', 440, 320, 300);
  @include adaptiveFull('margin-bottom', 40, 20, 20)
}
.secret__description {
  @include adaptiveFull('max-width', 410, 310, 300);
  @include adaptiveFull('margin-bottom', 40, 34, 20)
}
.secret__list {
  @include reset-list;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @include adaptiveFull('gap', 30, 30, 15);
  @include adaptiveFull('row-gap', 30, 20, 10);

  @include vp-767 {
    grid-template-columns: repeat(1, 1fr);
  }
}
.secret__item {
  display: flex;
  align-items: center;
  @include adaptiveFull('gap', 20, 13, 10)
}
.secret__item-img {
  @include adaptiveFull('width', 52, 38, 38)
}
.secret__item-text {
  margin: 0;
  font-size: 14px;
  font-weight: 300;
}
.secret__link {
  @include adaptiveFull('margin-top', 40, 30, 30);
  width: 180px;
}