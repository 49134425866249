.welcome {
  position: relative;
  color: $color-white;
  overflow: hidden;

  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-end;
    @include adaptiveFull('min-height', 980, 720, 720);
    @include adaptiveDesktopToMobile('padding-bottom', 90, 25);

    @include vp-767 {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 38px;
    }
  }
}
.welcome__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.welcome__text {
  max-width: 480px;


}
.welcome__title {
  @include adaptiveFull('max-width', 390, 290, 290);
  @include adaptiveFull('padding-bottom', 40, 24, 20);
  @include adaptiveFull('margin-bottom', 40, 24, 20);
  border-bottom: 1px solid #FFD289;
}
.welcome__description {
  @include adaptiveFull('max-width', 390, 300, 300);
  @include adaptiveFull('margin-bottom', 40, 24, 20);
}
.welcome__button {
}
.welcome__slider-wrapper {
  position: relative;

  .swiper-navigation {
  }
  .swiper-navigation__btn {
    position: absolute;
    top: 50%;
    left: -65px;
    @include adaptiveFull('left', -25, -25, -10);
    transform: translateY(-50%);
    z-index: 1;

    &.swiper-button-disabled {
      opacity: 0.5;
      pointer-events: none;

      @include vp-767 {
        opacity: 0;
      }
    }

    svg {
      @include adaptiveFull('width', 21, 15, 15);
      @include adaptiveFull('height', 42, 30, 30);
    }

    @include vp-767 {
      @include adaptiveFull('left', -25, -25, 5);
    }
  }
  .swiper-navigation__btn--prev {
    svg {
      transform: rotate(180deg);
    }
  }
  .swiper-navigation__btn--next {
    left: unset!important;
    @include adaptiveFull('right', -25, -25, -10);

    @include vp-767 {
      @include adaptiveFull('right', -25, -25, 5);
    }
  }
}
.welcome__slider {
  @include adaptiveFull('width', 680, 510, 175);

  .swiper {
  }
  .swiper-wrapper {
    
  }
  .swiper-slide {
  }

  @include vp-767 {
    @include adaptiveFull('--padding', 240, 110, 25);
    width: calc(100dvw - var(--padding) * 2);
  }
}
.welcome__slide {
  @include adaptiveFull('border-radius', 30, 20, 20);
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(15px);
  @include adaptiveFull('padding', 30, 20, 14);
  aspect-ratio: 1/1;

  @include hover-focus {
    .welcome__slide-photo {
      transform: scale(1.1);
    }
  }
}
.welcome__slide-title {
  @include adaptiveFull('font-size', 18, 16, 12);
  font-weight: 400;
  margin: 0;
  @include adaptiveFull('margin-bottom', 20, 13, 7);

  @include vp-1023 {
    font-size: 12px;
  }
}
.welcome__slide-arrow {
  @include adaptiveFull('width', 44, 32, 21);
  @include adaptiveFull('height', 44, 32, 21);
}
.welcome__slide-photo {
  transition: $trans-default;
  @include adaptiveFull('max-width', 280, 200, 50);
  transform: scale(1.1);
  transform-origin: bottom center;

  @include vp-767 {
    max-width: 100%;
  }
}